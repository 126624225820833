const useNavigationGame = () => {
	const route = useRoute();
	const showCatalogGames = useState<boolean>("show-catalog-games");
	const activeUrl = useState("active-url-games", () => route.path);
	const navigationContainer = ref<HTMLDivElement>();
	const startX = ref<number | null>(null);

	const isTabActive = (path: string) => path === activeUrl.value;

	const getTitle = (title?: string) => {
		if (!title) {
			return "";
		}

		return title.toLowerCase().replace(/ /g, "-");
	};

	const handleDragStart = (event: MouseEvent) => {
		if (navigationContainer.value?.contains(event.target as Node)) {
			event.preventDefault();
			startX.value = event.clientX;
		}
	};

	const handleMouseUp = () => {
		startX.value = null;
	};

	const scrollToActiveTab = () => {
		if (navigationContainer.value) {
			const containerRect = navigationContainer.value.getBoundingClientRect();
			const activeTab = navigationContainer.value.querySelector(".tab-active");
			if (activeTab) {
				const activeTabRect = activeTab.getBoundingClientRect();
				if (containerRect.width < activeTabRect.left + activeTabRect.width) {
					navigationContainer.value.scrollTo({
						left: activeTabRect.left,
						behavior: "smooth"
					});
				}
			}
		}
	};

	const handleDrag = (event: MouseEvent) => {
		if (startX.value !== null && navigationContainer.value) {
			const deltaX = event.clientX - startX.value;
			navigationContainer.value.scrollLeft -= deltaX;
			startX.value = event.clientX;
			event.preventDefault();
		}
	};

	const lobbyActive = () => {
		activeUrl.value = "/";
		showCatalogGames.value = false;
	};

	onMounted(() => {
		scrollToActiveTab();
		if (navigationContainer.value) {
			navigationContainer.value.addEventListener("mousedown", handleDragStart);
		}
		document.addEventListener("mousemove", handleDrag);
		document.addEventListener("mouseup", handleMouseUp);
	});

	onBeforeUnmount(() => {
		if (navigationContainer.value) {
			navigationContainer.value.removeEventListener("mousedown", handleDragStart);
		}
		document.removeEventListener("mousemove", handleDrag);
		document.removeEventListener("mouseup", handleMouseUp);
	});

	return { isTabActive, getTitle, lobbyActive, scrollToActiveTab, navigationContainer, activeUrl };
};

export default useNavigationGame;
